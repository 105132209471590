html {
  scroll-behavior: smooth;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 10px;
  font-family: poppins, sans-serif;
  color: #333; }

p {
  font-family: open-sans, sans-serif;
  color: #333;
  margin: 0 0 10px; }

a.button, button.button {
  display: block;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  padding: 16px 30px;
  font-family: proxima-nova, Helvetica, Arial, sans-serif;
  font-size: 15px;
  font-weight: 700;
  font-style: normal;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #fff;
  background-color: #272727;
  border: 1px solid #272727;
  -webkit-transition: 0.1s opacity linear;
  -moz-transition: 0.1s opacity linear;
  -o-transition: 0.1s opacity linear;
  transition: 0.1s opacity linear;
  -webkit-backface-visibility: hidden;
  /*
    font-family: open-sans, sans-serif;
    position: relative;
    overflow: hidden;
    transform: perspective(1px) translateZ(0px);
    transition-duration: 0.5s;
    transition-property: color;
    vertical-align: middle;
    background-color: #959595; //#5956fe;
    border: 1px solid #959595;
    color: #fff;
    display: inline-block;
    font-size: 18px;
    font-weight: 500;
    margin-top: 25px;
    padding: 12px 22px;
    text-transform: uppercase;
    font-style: normal;
    letter-spacing: 1px;
    svg {
        display: inline-block;
        width: 100%;
        max-width: 14px;
        height: auto;
        vertical-align: middle;
        margin-top: -4px;
        margin-left: 4px;
    }
    &:before {
        //background: #403df6 none repeat scroll 0 0;
        background: #828282 none repeat scroll 0 0;
        bottom: 0;
        content: "";
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        transform: scaleX(0);
        transform-origin: 0 50% 0;
        transition-duration: 0.5s;
        transition-property: transform;
        transition-timing-function: ease-out;
        z-index: -1;
        color: #fff;
    }
    &:focus,
    &:hover {
        outline: 0;
        text-decoration: none;
        color: #fff;
        &:before {
            transform: scaleX(1);
            //border-radius: 6px;
            transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
        }
    }
    */ }

.slick-arrow {
  display: block;
  width: 40px;
  height: 100%;
  background: none;
  border: none;
  color: transparent;
  font-size: 0;
  position: absolute;
  z-index: 4;
  top: 0; }
  .slick-arrow:after {
    content: " ";
    top: 50%;
    display: block;
    width: 15px;
    height: 15px;
    border-top: 2px solid #fff;
    position: absolute;
    -webkit-transition: all 350ms;
    -o-transition: all 350ms;
    transition: all 350ms; }
  .slick-arrow.slick-prev {
    left: 0; }
    .slick-arrow.slick-prev:after {
      border-left: 2px solid #fff;
      transform: rotate(-45deg);
      left: 15px; }
  .slick-arrow.slick-next {
    right: 0; }
    .slick-arrow.slick-next:after {
      border-right: 2px solid #fff;
      transform: rotate(45deg);
      right: 15px; }

header {
  display: flex;
  justify-content: center;
  min-height: 30vh;
  background-color: #f6f6f6;
  border-bottom: 1px solid #ddd;
  -webkit-box-shadow: 0px 0px 40px 5px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 40px 5px rgba(0, 0, 0, 0.05); }
  header .content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 3vh 0;}
    header .content h1 {
      font-size: 30px;
      margin-bottom: 5px;
      font-weight: 700;
      text-transform: uppercase;
      background: #0f449d;
      background: -moz-linear-gradient(90deg, #0f449d 0%, #d95a5a 34%, #a74c9d 66%, #0f449d 100%);
      background: -webkit-linear-gradient(90deg, #0f449d 0%, #d95a5a 34%, #a74c9d 66%, #0f449d 100%);
      background: linear-gradient(90deg, #0f449d 0%, #d95a5a 34%, #a74c9d 66%, #0f449d 100%);
      -webkit-text-fill-color: transparent;
      text-fill-color: transparent;
      -webkit-background-clip: text;
      background-clip: text;
      background-size: 250%;
      animation: textgradient 12s linear infinite; }
      @media only screen and (min-width: 740px) {
        header .content h1 {
          font-size: 5vh;
          margin-bottom: 0; } }
    header .content h2 {
      font-size: 24px;
      margin-bottom: 3px;
      font-family: open-sans, sans-serif;
      text-transform: uppercase;
      font-weight: 800; }
      @media only screen and (min-width: 740px) {
        header .content h2 {
          font-size: 4vh;
          margin-bottom: 15px; } }
    header .content .section-buttons {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 10px; }
      header .content .section-buttons button {
        max-width: 150px;
        margin: 0 14px;
        font-size: 20px;
        letter-spacing: 1px;
        width: 6vw;
        min-width: 130px;
        padding: 1vh 19px;
      }
        header .content .section-buttons button.active {
          background-color: #717171; }
          header .content .section-buttons button.active:focus:before, header .content .section-buttons button.active:hover:before {
            display: none; }
      header .content .social {
        display: flex;
        margin-top: 40px;
      }
        header .content .social a {
          display: flex;
          width: 100%;
          max-width: 30px;
          margin: 0 0.45vw;
        }
          header .content .social a img {
            display: block;
            width: 100%;
          }
      @media only screen and (min-width: 740px) {
        header .content .section-buttons button {
          max-width: 160px;
          margin: 0 14px;
          font-size: 20px;
          letter-spacing: 1px;
          width: 7vw;
          min-width: 140px;
          padding: 1.5vh 30px;
        }
        header .content .social {
          display: flex;
        }
      }

nav.menu {
  display: none;
  background-color: #fafafa;
  border-bottom: 1px solid #ddd; }
  nav.menu ul {
    list-style: none;
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0; }
    nav.menu ul li {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50%; }
      nav.menu ul li a {
        display: block;
        width: 100%;
        text-align: center;
        line-height: 1;
        padding: 30px 0px;
        color: #000;
        text-transform: uppercase;
        text-decoration: none;
        font-size: 24px;
        letter-spacing: 2px;
        font-family: poppins, sans-serif;
        font-weight: bold;
        vertical-align: middle;
        -webkit-transform: perspective(1px) translateZ(0);
        transform: perspective(1px) translateZ(0);
        box-shadow: 0 0 1px transparent;
        position: relative;
        -webkit-transition-property: color;
        transition-property: color;
        -webkit-transition-duration: 0.5s;
        transition-duration: 0.5s; }
        nav.menu ul li a:before {
          content: "";
          position: absolute;
          z-index: -1;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: #2098D1;
          -webkit-transform: scaleY(0);
          transform: scaleY(0);
          -webkit-transform-origin: 50% 100%;
          transform-origin: 50% 100%;
          -webkit-transition-property: transform;
          transition-property: transform;
          -webkit-transition-duration: 0.5s;
          transition-duration: 0.5s;
          -webkit-transition-timing-function: ease-out;
          transition-timing-function: ease-out; }
        nav.menu ul li a:hover, nav.menu ul li a:focus, nav.menu ul li a:active {
          color: white; }
        nav.menu ul li a:hover:before, nav.menu ul li a:focus:before, nav.menu ul li a:active:before {
          -webkit-transform: scaleY(1);
          transform: scaleY(1);
          -webkit-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
          transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66); }

.portfolio {
  margin-top: 50px; }
  .portfolio h2 {
    font-size: 6.2vw;
    margin-bottom: 20px;
    font-weight: 600;
    text-transform: uppercase;
    text-align: center; }
    @media only screen and (min-width: 1024px) {
      .portfolio h2 {
        font-size: 30px; } }
  .portfolio #web {
    display: none; }
  .portfolio #hobby {
    display: none; }
    .portfolio #hobby .project-details {
      margin: 35px 20px; }
      .portfolio #hobby .project-details h2 {
        text-align: left; }
      .portfolio #hobby .project-details p {
        color: #666;
        font-size: 18px; }
  .portfolio .portfolio-menu {
    margin-bottom: 40px; }
    .portfolio .portfolio-menu button {
      outline: medium none;
      transition: all 0.3s ease 0s;
      background: transparent none repeat scroll 0 0;
      border: 2px solid rgba(0, 0, 0, 0.1);
      font-weight: 500;
      letter-spacing: 1px;
      margin: 10px 6px;
      color: #818181;
      padding: 5px 10px;
      position: relative;
      text-transform: uppercase;
      border-radius: 4px;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.06); }
      .portfolio .portfolio-menu button:before {
        background: #272727 none repeat scroll 0 0;
        border-radius: 50px;
        bottom: -3px;
        content: "";
        height: 4px;
        left: 0;
        margin: 0 auto;
        position: absolute;
        right: 0;
        transform: scaleX(0);
        transition: all 0.3s ease 0s;
        width: auto; }
      .portfolio .portfolio-menu button:hover, .portfolio .portfolio-menu button.active {
        color: #272727; }
        .portfolio .portfolio-menu button:hover:before, .portfolio .portfolio-menu button.active:before {
          transform: scaleX(1); }
  .portfolio .nova-cup-grid {
    width: 100%;
    max-width: 1260px;
    margin: 0 auto; }
    @media only screen and (min-width: 1024px) and (max-height: 1100px) {
      .portfolio .nova-cup-grid {
        max-width: 130vh; } }
    .portfolio .nova-cup-grid .cup-grid {
      margin: 0 20px;
      display: flex;
      flex-direction: column;
      margin-bottom: 40px; }
      @media only screen and (min-width: 1024px) {
        .portfolio .nova-cup-grid .cup-grid {
          flex-direction: row; } }
      @media only screen and (max-width: 1023px) {
        .portfolio .nova-cup-grid .cup-grid .grid-left {
          order: 2; } }
      @media only screen and (min-width: 1024px) {
        .portfolio .nova-cup-grid .cup-grid .grid-left {
          width: 56%;
          display: flex;
          flex-direction: column;
          justify-content: space-between; } }
      @media only screen and (min-width: 1500px) {
        .portfolio .nova-cup-grid .cup-grid .grid-left {
          width: 56%; } }
      .portfolio .nova-cup-grid .cup-grid .grid-left #nova-cup-model {
        position: relative;
        width: 100%;
        border: 1px solid #23a6d5;
        background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
        background-size: 400% 400%;
        animation: gradient 12s ease infinite; }
        @media only screen and (max-width: 1023px) {
          .portfolio .nova-cup-grid .cup-grid .grid-left #nova-cup-model {
            height: 427px;
            margin-bottom: 20px; } }
        @media only screen and (min-width: 1024px) {
          .portfolio .nova-cup-grid .cup-grid .grid-left #nova-cup-model {
            height: calc(44.75% - 20px); } }
        .portfolio .nova-cup-grid .cup-grid .grid-left #nova-cup-model .click-to-interact {
          position: absolute;
          bottom: 2px;
          right: 5px;
          font-size: 14px;
          color: #fff; }
          .portfolio .nova-cup-grid .cup-grid .grid-left #nova-cup-model .click-to-interact .interact-mobile {
            display: none; }
          .portfolio .nova-cup-grid .cup-grid .grid-left #nova-cup-model .click-to-interact .interact-desktop {
            display: inline-block; }
        .portfolio .nova-cup-grid .cup-grid .grid-left #nova-cup-model .model-overlay {
          display: none;
          justify-content: center;
          align-items: center;
          position: absolute;
          width: 100%;
          height: 100%;
          z-index: 3; }
          .portfolio .nova-cup-grid .cup-grid .grid-left #nova-cup-model .model-overlay .loading {
            font-weight: bold;
            font-size: 18px;
            color: #fff; }
      .portfolio .nova-cup-grid .cup-grid .grid-left #nova-cup {
        position: relative;
        width: 100%;
        display: block; }
        @media only screen and (min-width: 1024px) {
          .portfolio .nova-cup-grid .cup-grid .grid-left #nova-cup {
            height: 55.25%;
            overflow: hidden; } }
        .portfolio .nova-cup-grid .cup-grid .grid-left #nova-cup .nova-cup-slider {
          display: block;
          width: 100%;
          position: relative; }
          .portfolio .nova-cup-grid .cup-grid .grid-left #nova-cup .nova-cup-slider img {
            display: block;
            width: 100%;
            max-width: 100%; }
        .portfolio .nova-cup-grid .cup-grid .grid-left #nova-cup .nova-cup-slider-nav {
          /*margin-top: 20px;*/ margin-top: 1px; }
          .portfolio .nova-cup-grid .cup-grid .grid-left #nova-cup .nova-cup-slider-nav .slick-slide {
            cursor: pointer; }
            .portfolio .nova-cup-grid .cup-grid .grid-left #nova-cup .nova-cup-slider-nav .slick-slide img {
              width: 98%; }
      @media only screen and (max-width: 1023px) {
        .portfolio .nova-cup-grid .cup-grid .grid-right {
          order: 1;
          margin-bottom: 20px; } }
      @media only screen and (min-width: 1024px) {
        .portfolio .nova-cup-grid .cup-grid .grid-right {
          width: 44%;
          padding-left: 20px; } }
      .portfolio .nova-cup-grid .cup-grid .grid-right #nova-cup-video video {
        display: block;
        width: 100%; }
  .portfolio #work-grid {
    width: 100%;
    max-width: 1500px;
    margin: 0 auto 12vw auto; }
  .portfolio .work-grid {
    width: calc(100% - 20px); }
    .portfolio .work-grid .work-object {
      position: relative;
      width: 100%;
      float: left;
      margin-bottom: 20px;
      min-height: 1px;
      padding-right: 0;
      padding-left: 20px; }
      @media only screen and (min-width: 1024px) {
        .portfolio .work-grid .work-object {
          width: 50%;
          padding-right: 0;
          /*
        padding-left: 20px;
        padding-right: 10px;
        
        &:nth-child(even) {
          padding-left: 10px;
          padding-right: 20px;
        }
        */ }
        .portfolio .work-grid .work-object:not(.video):after {
          content: "";
          display: block;
          padding-bottom: 100%;
        }
        .portfolio .work-grid .work-object:not(.video) .work-item {
          position: absolute;
          width: calc(100% - 20px);
        }

        .portfolio .work-grid .work-object.video .work-item {
          position: absolute;
          width: calc(100% - 20px);
        }
        .portfolio .work-grid .work-object.video:after {
          content: "";
          display: block;
          padding-bottom: 56.2%;
        }
      }
        .portfolio .work-grid .work-object.hide { display: none; }
      .portfolio .work-grid .work-object .view {
        display: none; }
        @media only screen and (max-width: 1023px) {
          .portfolio .work-grid .work-object .view {
            display: flex;
            position: absolute;
            right: 7%;
            top: 5%;
            z-index: 8;
            width: 75px;
            height: 75px;
            border-radius: 50%;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            background: #525252 none repeat scroll 0 0;
            border-radius: 50%;
            transition: all 0.4s ease 0s;
            cursor: pointer;
            border: 1px solid rgba(0, 0, 0, 0.25); }
            .portfolio .work-grid .work-object .view svg {
              width: 80%;
              display: block; } }
      .portfolio .work-grid .work-object .work-item {
        -webkit-box-shadow: 0 0 40px rgba(0, 0, 0, 0.12);
        -moz-box-shadow: 0 0 40px rgba(0, 0, 0, 0.12);
        box-shadow: 0 0 40px rgba(0, 0, 0, 0.12);
        border: 1px solid rgba(127, 136, 151, 0.5);
        overflow: hidden;
        position: relative; }
        @media only screen and (min-width: 1024px) {
          .portfolio .work-grid .work-object .work-item {
            /*cursor: pointer;*/ } }
        .portfolio .work-grid .work-object .work-item.motion {
          position: relative;
          overflow: hidden;
          width: 100%; }
          .portfolio .work-grid .work-object .work-item.motion:after {
            content: "";
            display: block;
            padding-bottom: 56%; }
          .portfolio .work-grid .work-object .work-item.motion video,
          .portfolio .work-grid .work-object .work-item.motion img {
            position: absolute;
            z-index: 1; }
          .portfolio .work-grid .work-object .work-item.motion.zoa video {
            width: 105%;
            left: -2.5%;
            top: -1%; }
        .portfolio .work-grid .work-object .work-item .work-details {
          position: absolute;
          z-index: 2;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.87);
          opacity: 0;
          top: 0;
          left: 0;
          transition: opacity 0.3s;
          -webkit-transition: opacity 0.3s;
          color: #fff;
          display: flex;
          flex-direction: column;
          justify-content: space-between; }
          @media only screen and (max-width: 1023px) {
            .portfolio .work-grid .work-object .work-item .work-details {
              display: none; } }
          .portfolio .work-grid .work-object .work-item .work-details h2 {
            font-weight: bold;
            color: #fff;
            margin-bottom: 8px;
            text-align: left; }
          .portfolio .work-grid .work-object .work-item .work-details h3 {
            position: absolute;
            top: 0;
            right: 0;
            color: #fff;
            padding: 2%; }
          .portfolio .work-grid .work-object .work-item .work-details h2,
          .portfolio .work-grid .work-object .work-item .work-details p,
          .portfolio .work-grid .work-object .work-item .work-details a {
            color: #fff; }
          .portfolio .work-grid .work-object .work-item .work-details a {
            display: block;
            text-decoration: underline;
            margin-bottom: 5px; }
          .portfolio .work-grid .work-object .work-item .work-details img {
            position: relative;
            z-index: 3;
            display: none; }
            @media only screen and (min-width: 1300px) {
              .portfolio .work-grid .work-object .work-item .work-details img {
                display: block; } }
          .portfolio .work-grid .work-object .work-item .work-details .details {
            padding: 2%;
            position: relative; }
            .portfolio .work-grid .work-object .work-item .work-details .details ul li a {
              text-decoration: underline; display: inline; }
        @media only screen and (min-width: 1024px) {
          .portfolio .work-grid .work-object .work-item:hover .work-details {
            opacity: 1; } }
        @media only screen and (max-width: 1023px) {
          .portfolio .work-grid .work-object .work-item.mobile-visible .work-details {
            display: flex;
            left: 0;
            right: 0;
            margin: auto;
            width: 100%;
            opacity: 1;
            height: 100vh;
            position: fixed;
            z-index: 9; }
            .portfolio .work-grid .work-object .work-item.mobile-visible .work-details .close {
              display: block;
              width: 50px;
              height: 50px;
              position: absolute;
              top: 0;
              right: 0;
              margin-top: 1%;
              cursor: pointer;
              z-index: 10; }
              .portfolio .work-grid .work-object .work-item.mobile-visible .work-details .close:before {
                content: 'x';
                color: #fff;
                font-weight: bold;
                font-size: 36px; }
            .portfolio .work-grid .work-object .work-item.mobile-visible .work-details .details {
              padding: 10% 5% 5% 5%; }
              .portfolio .work-grid .work-object .work-item.mobile-visible .work-details .details h3 {
                position: relative;
                margin-top: 10px;
                padding: 0; } }
      .portfolio .work-grid .work-object video {
        display: block;
        width: 100%;
        height: auto;
        object-fit: fill; }
      .portfolio .work-grid .work-object img {
        display: block;
        width: 100%;
        height: auto; }
  .portfolio #boat {
    position: relative;
    width: 100%;
    max-width: calc(100% - 40px);
    margin: 120px auto 12vw auto;
    display: block; }
    @media only screen and (min-width: 1024px) and (max-height: 1100px) {
      .portfolio #boat {
        max-width: 130vh;
        padding-left: 20px;
        padding-right: 20px; } }
    @media only screen and (min-width: 1260px) and (min-height: 1101px) {
      .portfolio #boat {
        max-width: 1220px;
        padding: 0; } }
    .portfolio #boat .boat-slider {
      display: block;
      width: 100%;
      position: relative; }
      .portfolio #boat .boat-slider img,
      .portfolio #boat .boat-slider video {
        display: block;
        width: 100%;
        max-width: 100%; }
    .portfolio #boat .boat-slider-thumbs {
      margin-top: 20px; }
      .portfolio #boat .boat-slider-thumbs .slick-slide {
        cursor: pointer; }
        .portfolio #boat .boat-slider-thumbs .slick-slide img {
          width: 95%; }
    .portfolio #boat .project-details {
      margin: 35px 0; }
      .portfolio #boat .project-details h2 {
        text-align: left; }
      .portfolio #boat .project-details p {
        color: #666;
        font-size: 18px; }

@keyframes textgradient {
  to {
    background-position: 200% center; } }

@keyframes gradient {
  0% {
    background-position: 0% 50%; }
  50% {
    background-position: 100% 50%; }
  100% {
    background-position: 0% 50%; } }

html.touch .click-to-interact .interact-mobile {
  display: inline-block !important; }

html.touch .click-to-interact .interact-desktop {
  display: none !important; }

footer {
  display: block;
  text-align: center;
  margin-top: 4vw;
  margin-bottom: 3vw; }
  footer p {
    font-style: italic; }
.clearfix {
  clear:both;
}
.page-tab.active-tab {
  display: block !important;
}